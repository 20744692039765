import React from 'react'
import HeroGradient from 'components/hero-image/hero-image-gradient'
import { graphql, Link } from 'gatsby'
import { Helmet } from 'react-helmet'
import Layout from 'components/layout'
import CategoryContent from 'components/category/content'
import Floater from 'components/floating-grid'
import FeaturesGrid from 'components/features-grid'
import Copy from 'components/category/copy'
import BrandsModule from 'components/modules/brands'

const items = [
  {
    link: '/lawn-garden/',
    imageId: 'lawnGardenImage',
    name: 'Lawn & Garden',
    alt: 'John Deere Lawn and Garden Equipment',
  },
  {
    link: '/agriculture/',
    imageId: 'agricultureImage',
    name: 'Agriculture',
    alt: 'John Deere Agriculture Equipment',
  },
  {
    link: '/construction/',
    imageId: 'constructionImage',
    name: 'Construction',
    alt: 'John Deere Construction Equipment',
  },
  {
    link: '/used-equipment/',
    imageId: 'usedImage',
    name: 'Used Equipment',
    alt: 'John Deere Used Equipment',
  },
  {
    link: '/implements/',
    imageId: 'implementsImage',
    name: 'Implements',
    alt: 'Implements',
  },
  {
    link: '/stihl/',
    imageId: 'stihlImage',
    name: 'STIHL',
    alt: 'STIHL',
  },
]

const EquipmentPage = ({ data }) => {
  const { governmentImage, heroImage } = data
  const gridItems = items.map(item => {
    item.image = data[item.imageId]
    return item
  })
  return (
    <Layout>
      <Helmet>
        <title>John Deere Equipment | Hutson Inc</title>
        <meta
          name='description'
          content='From STIHL outdoor power tools to new and used large ag equipment, Hutson has you covered. Find chainsaws, trimmers, mowers, gators, tractors, and much more.'
        />
        <script type='application/ld+json'>
          {JSON.stringify({
            '@context': 'http://schema.org',
            '@type': 'BreadcrumbList',
            'itemListElement': [
              {
                '@type': 'ListItem',
                'position': 1,
                'name': 'Hutson Inc',
                'item': 'https://www.hutsoninc.com/',
              },
              {
                '@type': 'ListItem',
                'position': 2,
                'name': 'Equipment',
                'item': 'https://www.hutsoninc.com/equipment/',
              },
            ],
          })}
        </script>
      </Helmet>

      <HeroGradient image={heroImage} heading='Equipment' />

      <CategoryContent>
        <Floater items={gridItems} />

        <Copy header='Equipment at Hutson'>
          <p>
            Hutson carries a full line of John Deere equipment including{' '}
            <Link to='/construction/'>light construction equipment</Link>,{' '}
            <Link to='/implements/'>Frontier and John Deere implements</Link>, and John Deere
            attachments. We also have a{' '}
            <Link to='/used-equipment/'>large selection of used equipment</Link> and carry several
            product lines including <Link to='/stihl/'>STIHL</Link>, Billy Goat, Landoll, Pottinger,
            and much more.{' '}
            <Link to='/product-lines/'>Click here to check out our full list of product lines</Link>
            .
          </p>
        </Copy>
      </CategoryContent>

      <FeaturesGrid
        features={[
          {
            image: governmentImage.childImageSharp.gatsbyImageData,
            imageAlt: 'John Deere equipment in a field.',
            title: 'Government Sales',
            copy: 'Making the most of your time and funds is a critical. Government agencies and municipalities that work with Hutson won’t have to deal with the bid process. We carry a range of equipment including: commercial mowers, tractors, light construction equipment, handheld power equipment, and implements.',
            button: {
              link: '/government-sales/',
              text: 'Learn more about Government Sales at Hutson',
            },
          },
        ]}
      />

      <BrandsModule />
    </Layout>
  )
}

export const pageQuery = graphql`
  {
    governmentImage: file(relativePath: { eq: "government/government-feature.jpg" }) {
      ...SharpImage900
    }
    heroImage: file(relativePath: { eq: "headers/equipment-header.jpg" }) {
      ...FullWidthImage
    }
    lawnGardenImage: file(relativePath: { eq: "lawn-and-garden/x700-series.jpg" }) {
      ...FloatingGridImage
    }
    agricultureImage: file(relativePath: { eq: "agriculture/6-family.jpg" }) {
      ...FloatingGridImage
    }
    constructionImage: file(relativePath: { eq: "construction/skid-steers.jpg" }) {
      ...FloatingGridImage
    }
    usedImage: file(relativePath: { eq: "lawn-and-garden/4-family.jpg" }) {
      ...FloatingGridImage
    }
    implementsImage: file(
      relativePath: { eq: "implements/utility-tractors/landscape-equipment/box-blades.jpg" }
    ) {
      ...FloatingGridImage
    }
    stihlImage: file(relativePath: { eq: "stihl/chain-saws.jpg" }) {
      ...FloatingGridImage
    }
  }
`

export default EquipmentPage
